export default {
    data() {
        return {
            start: 0,
            limit: 20
        }
    },
    methods: {
        fetchMore(query, updateQuery) {
            this.start += this.limit
            query.fetchMore({
                variables: {
                    start: this.start,
                    limit: this.limit
                },
                updateQuery
            })
        }
    }
}
