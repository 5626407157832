<template>
    <div class="conversation text-primary bg-light">
        <div class="conversation__header bg-white">
            <img
                src="@/assets/icons/left_arrow_icon.svg"
                :style="{ cursor: 'pointer', width: '20px', height: '20px' }"
                @click="$router.back()"
            />
            <LoadingEllipsis v-if="$apollo.loading">Fetching</LoadingEllipsis>
            <h3 v-else>{{ otherName }}</h3>
            <img src="@/assets/ellipsis.svg" />
        </div>
        <CollapsiblePanelStateless
            :is-collapsed="!isRequestDetailsVisible"
            :style="{ position: 'relative', backgroundColor: 'white' }"
        >
            <template #header>
                <div
                    class="panel-header"
                    @click="isRequestDetailsVisible = !isRequestDetailsVisible"
                >
                    <span>{{ !isRequestDetailsVisible ? 'View details' : 'Hide details' }}</span>
                    <img
                        src="@/assets/icons/chevron_up_icon.svg"
                        class="chevron"
                        :class="{ 'flip-180': !isRequestDetailsVisible }"
                    />
                </div>
            </template>
            <template #content>
                <div class="panel-content">
                    <RequestDetails
                        v-if="eventrequest"
                        :event="{ ...eventrequest.event, price: eventrequest.price }"
                    />
                </div>
            </template>
        </CollapsiblePanelStateless>
        <Chat
            v-if="!!myConversation"
            :right-id="getMyCurrentProfile().id"
            :members="getMembers"
            :messages="getMessages"
            :loading="$apollo.queries.myConversation.loading"
            @scrolled-top="loadMoreMessages"
        >
        </Chat>
        <div v-else class="conversation__chat-wrapper"></div>
        <div class="conversation__input bg-light">
            <div
                v-if="shouldShowEditEventButton && eventrequest"
                :style="{ display: 'grid', rowGap: '1rem', width: '260px' }"
            >
                <Button
                    v-if="eventrequest.requeststatus === 'DECLINED'"
                    label="Request is canceled"
                    primary
                    disabled
                />
                <Button
                    v-if="eventrequest.requeststatus === 'ACCEPTED'"
                    label="Request is complete"
                    primary
                    disabled
                />
                <Button
                    v-else-if="eventrequest.status === 'ACCEPTED'"
                    :label="`Go for this ${eventrequest.venue ? 'venue' : 'artist'}`"
                    fluid
                    primary
                    @click="confirmOffer"
                />
                <Button
                    label="Edit event"
                    fluid
                    @click="$router.push('/edit-event/' + eventrequest.event.id)"
                />
            </div>
            <div
                v-else-if="eventrequest"
                :style="{ display: 'grid', rowGap: '1rem', width: '260px' }"
            >
                <!-- when requeststatus is DECLINED -->
                <div v-if="eventrequest.requeststatus === 'DECLINED'">
                    <p class="font-weight-medium text--red">
                        This request has been cancelled by the creator
                    </p>
                </div>
                <!-- when requeststatus is ACCEPTED -->
                <div v-else-if="eventrequest.requeststatus === 'ACCEPTED'">
                    <div>
                        Congratulations, you have been selected as the
                        {{
                            getMyCurrentProfile() && getMyCurrentProfile().__typename.toLowerCase()
                        }}
                        for this event.
                    </div>
                </div>
                <!-- when status is ACCEPTED -->
                <div v-else-if="eventrequest.status === 'ACCEPTED'">
                    <!-- <Button label="Cancel request" fluid @click="cancelRequest" /> -->
                    <p :style="{ color: 'green' }">
                        You have accepted the request. Waiting for {{ otherName }} to confirm...
                    </p>
                </div>
                <!-- when status is DECLINED -->
                <div
                    v-else-if="eventrequest.status === 'DECLINED'"
                    :style="{ display: 'grid', rowGap: '1rem' }"
                >
                    <Button label="Declined" :disabled="true" />
                </div>
                <!-- status is PENDING -->
                <div v-else :style="{ display: 'grid', rowGap: '1rem' }">
                    <Button label="Accept" primary fluid @click="acceptRequest" />
                    <Button
                        label="Reject"
                        fluid
                        :style="{ border: '2px solid' }"
                        @click="declineRequest"
                    />
                </div>
            </div>
            <form
                :style="{ width: '100%', paddingTop: '1rem', borderTop: '1px solid white' }"
                @submit.prevent="handleSendMessage"
            >
                <BaseInput v-model="input" placeholder="Chat" round required />
            </form>
        </div>
    </div>
</template>

<script>
import Chat from '@/components/chat/Chat'
import CollapsiblePanelStateless from '@/components/CollapsiblePanelStateless'
import RequestDetails from '@/components/chat/RequestDetails'
import { mergeUniquesById } from '@/utils/common'
import { MY_EVENT, EVENT } from '@/graphql/queries/event'
import { ME } from '@/graphql/queries/user'
import {
    UPDATE_EVENTREQUEST_STATUS,
    ACCEPT_EVENT_REQUEST_OFFER
} from '@/graphql/mutations/eventRequest.js'
import { EVENTREQUEST, MY_EVENTREQUESTS } from '@/graphql/queries/eventRequest.js'

import { MY_CONVERSATION } from '@/graphql/queries/conversation.js'
import { SEND_EVENTREQUEST_MESSAGE } from '@/graphql/mutations/conversation.js'
import conversationMixin from './ConversationMixin'
import PaginationMixin from './PaginationMixin'
export default {
    name: 'RequestConversation',
    components: {
        Chat,
        RequestDetails,
        CollapsiblePanelStateless
    },
    apollo: {
        me: {
            query: ME
        },
        eventrequest: {
            query: EVENTREQUEST,
            variables() {
                return {
                    id: this.$route.params.id
                }
            },
            fetchPolicy: 'network-only'
        },
        event: {
            query: EVENT,
            variables() {
                return {
                    id: this.eventId
                }
            },
            fetchPolicy: 'network-only',
            skip() {
                return !this.eventId
            }
        },
        myConversation: {
            query: MY_CONVERSATION,
            variables() {
                return {
                    id: this.conversationId,
                    start: 0,
                    limit: this.limit
                }
            },
            fetchPolicy: 'network-only',
            pollInterval: 15000,
            skip() {
                return !this.conversationId
            }
        }
    },
    mixins: [conversationMixin, PaginationMixin],
    inject: ['getMyCurrentProfile'],

    data() {
        return {
            isRequestDetailsVisible: false
        }
    },
    computed: {
        shouldShowEditEventButton() {
            return this.eventrequest?.event?.fan?.id === this.getMyCurrentProfile()?.id
        },
        conversationId() {
            return this.eventrequest?.conversation?.id
        },
        eventId() {
            return this.eventrequest?.event?.id
        },
        other() {
            return this.myConversation?.members?.find(
                (member) => member.id !== this.getMyCurrentProfile().id
            )
        },
        otherName() {
            return this.other?.name
        },
        getMembers() {
            const members = this.myConversation?.members
            if (members) {
                return members
            } else {
                return []
            }
        },
        getMessages() {
            const messages = this.myConversation?.messages || []

            return [...messages].reverse()
        }
    },
    methods: {
        loadMoreMessages() {
            this.fetchMore(this.$apollo.queries.myConversation, (previous, { fetchMoreResult }) => {
                return {
                    myConversation: {
                        ...previous.myConversation,
                        messages: mergeUniquesById(
                            previous.myConversation.messages,
                            fetchMoreResult.myConversation.messages
                        )
                    }
                }
            })
        },
        async handleSendMessage() {
            const message = this.extractInputText()
            try {
                await this.$apollo.mutate({
                    mutation: SEND_EVENTREQUEST_MESSAGE,
                    variables: {
                        input: {
                            id: this.eventrequest.id,
                            message
                        }
                    },
                    optimisticResponse: {
                        __typename: 'Mutation',
                        eventRequestMessage: {
                            __typename: 'Conversation',
                            id: this.myConversation.id,
                            updatedAt: new Date(),
                            lastMessage: {
                                _id: 'TEMP_MESSAGE_ID:' + Math.floor(Math.random() * 1000000),
                                message,
                                profileId: this.getMyCurrentProfile().id,
                                createdBy: this.me.id
                            }
                        }
                    },
                    // From mixin
                    update: this.updateCacheOnSendMessage('eventRequestMessage', {
                        query: MY_CONVERSATION,
                        variables: {
                            id: this.myConversation.id,
                            start: 0,
                            limit: this.limit
                        }
                    })
                })
            } catch (error) {
                console.error({ error })
            }
        },
        async acceptRequest() {
            try {
                const result = await this.$apollo.mutate({
                    mutation: UPDATE_EVENTREQUEST_STATUS,
                    variables: {
                        input: {
                            id: this.eventrequest.id,
                            status: 'ACCEPTED'
                        }
                    },
                    optimisticResponse: {
                        __typename: 'Mutation',
                        updateEventRequestStatus: {
                            id: this.eventrequest.id,
                            status: 'ACCEPTED',
                            __typename: 'Eventrequest'
                        }
                    },
                    refetchQueries: [{ query: MY_EVENTREQUESTS }]
                })
                return result.data.updateEventRequestStatus
            } catch (err) {
                console.log({ err })
                console.error('Something bad happened when accepting an EventRequest.')
            }
        },
        async declineRequest() {
            try {
                await this.$apollo.mutate({
                    mutation: UPDATE_EVENTREQUEST_STATUS,
                    variables: {
                        input: {
                            id: this.eventrequest.id,
                            status: 'DECLINED'
                        }
                    },
                    optimisticResponse: {
                        __typename: 'Mutation',
                        updateEventRequestStatus: {
                            id: this.eventrequest.id,
                            status: 'DECLINED',
                            __typename: 'Eventrequest'
                        }
                    },
                    refetchQueries: [{ query: MY_EVENTREQUESTS }]
                })
            } catch (err) {
                console.error('Something bad happened when declining an EventRequest.')
            }
        },
        async confirmOffer() {
            try {
                const result = await this.$apollo.mutate({
                    mutation: ACCEPT_EVENT_REQUEST_OFFER,
                    variables: {
                        input: {
                            id: this.eventrequest.id,
                            accept: true
                        }
                    },
                    refetchQueries: [
                        { query: MY_EVENTREQUESTS },
                        { query: MY_EVENT, variables: { id: this.eventrequest.event.id } }
                    ]
                })
                const { data: acceptEventRequestOffer } = result
                console.log(acceptEventRequestOffer)
                this.$router.go(-1)
            } catch (error) {
                console.error('Something bad happened when confirming an EventRequest.')
            }
        },
        async cancelRequest() {
            try {
                await this.$apollo.mutate({
                    mutation: UPDATE_EVENTREQUEST_STATUS,
                    variables: {
                        input: {
                            id: this.eventrequest.id,
                            status: 'CANCELED'
                        }
                    },
                    optimisticResponse: {
                        __typename: 'Mutation',
                        updateEventRequestStatus: {
                            id: this.eventrequest.id,
                            status: 'CANCELED',
                            __typename: 'Eventrequest'
                        }
                    },
                    refetchQueries: [{ query: MY_EVENTREQUESTS }]
                })
            } catch (err) {
                console.error('Something bad happened when canceling an EventRequest.')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

.flip-180 {
    transform: rotate(-180deg);
}
.chevron {
    transition: transform 0.3s;
}
.panel-header {
    background-color: white;
    border-top: solid thin $light;
    padding: 1rem;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr auto;
}

.panel-content {
    background-color: $light;
    padding: 1rem;
    position: fixed;
    width: 100%;
    transform-origin: top;
    box-shadow: 0 6px 10px 0px rgba(0, 0, 0, 0.1);
}

.request-info {
    display: grid;
    justify-content: start;
    justify-items: start;
    row-gap: 0.5rem;
    grid-template-columns: repeat(2, auto);
    column-gap: 0.5rem;
    align-items: center;
    &__item {
        border-radius: 10px;
        background-color: white;
        padding: 0.5rem 1rem;
        width: auto;
        font-size: 14px;
    }
}
.conversation {
    height: 100vh;
    display: grid;
    grid-template-rows: auto auto 1fr auto;

    &__chat-wrapper {
        overflow: auto;
    }
    &__header {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        align-items: center;
        padding: 3rem 1rem 1rem;
        > :last-child {
            justify-self: end;
        }
    }

    &__input {
        width: 100%;
        padding: 0.5rem 1rem;
        padding-bottom: 2rem;
        display: grid;
        justify-items: center;
        row-gap: 1rem;
    }
}
</style>
