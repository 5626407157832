import gql from 'graphql-tag'

export const CREATE_USER_EVENTREQUEST = gql`
    mutation CreateUserEventRequest($input: createEventrequestInput) {
        createUserEventRequest(input: $input) {
            ... on ResponseError {
                errors {
                    message
                }
            }
            ... on createEventrequestPayload {
                eventrequests {
                    name
                    id
                }
            }
        }
    }
`

export const UPDATE_EVENTREQUEST_STATUS = gql`
    mutation UpdateEventRequestStatus($input: EventrequestStatusInput!) {
        updateEventRequestStatus(input: $input) {
            id
            status
        }
    }
`

export const ACCEPT_EVENT_REQUEST_OFFER = gql`
    mutation AcceptEventRequestOffer($input: AcceptEventrequestOfferInput!) {
        acceptEventRequestOffer(input: $input) {
            id
            name
            status
            event {
                id
                status
                name
                artist {
                    id
                }
                venue {
                    id
                }
            }
        }
    }
`
