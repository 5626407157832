<template>
    <div v-if="Object.keys(event).length" class="request-details">
        <small>Event</small>
        <p class="font-weight-medium">
            {{ event.name }}
        </p>
        <small>Place</small>
        <p class="font-weight-medium">
            {{ event.locationText }}
        </p>
        <small>Date</small>
        <p class="font-weight-medium">
            {{ startDate }}
        </p>
        <small>Time</small>
        <p class="font-weight-medium">
            {{ startTime + ' - ' + endTime }}
        </p>
        <small>Price</small>
        <p class="font-weight-medium">kr. {{ event.price }}</p>
    </div>
</template>

<script>
import { format } from 'date-fns'

export default {
    name: 'RequestDetails',
    props: {
        event: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        startDate() {
            if (!this.event.eventStart) {
                return '...'
            }
            return format(new Date(this.event.eventStart), 'dd/MM/yyyy')
        },
        startTime() {
            if (!this.event.eventStart) {
                return '...'
            }
            return format(new Date(this.event.eventStart), 'HH:mm')
        },
        endTime() {
            if (!this.event.eventEnd) {
                return '...'
            }
            return format(new Date(this.event.eventEnd), 'HH:mm')
        }
    }
}
</script>

<style lang="scss" scoped>
.request-details {
    display: grid;
    grid-template-columns: repeat(2, auto);
    place-content: start;
    row-gap: 4px;
    column-gap: 1rem;
    align-items: center;
    justify-items: start;

    small {
        line-height: 1rem;
    }
    p {
        font-size: 14px !important;
        padding: 0.75rem;
        line-height: 1rem;
        border-radius: 5px;
        background-color: #fff;
    }
}
</style>
