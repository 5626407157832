var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"body",staticClass:"chat"},[_vm._t("top"),_c('div',{staticClass:"chat__message-container"},[(_vm.loading)?_c('LoadingEllipsis',[_vm._v("Loading")]):_vm._e(),_vm._l((_vm.groupedMessages),function(messageGroup){return _c('div',{key:messageGroup[0].id + 'group',staticClass:"message-group",class:{ 'message-group--left': !_vm.isRightAligned(messageGroup[0].profileId) }},[(!_vm.isRightAligned(messageGroup[0].profileId))?_c('div',{staticClass:"message-group__avatar-gutter"},[_c('img',{staticClass:"message-group__avatar avatar-sm",attrs:{"src":_vm.getAvatar(messageGroup[0].profileId),"alt":"concert-time"}})]):_vm._e(),_c('div',{staticClass:"message-group__messages"},_vm._l((messageGroup),function(ref){
var message = ref.message;
var id = ref.id;
var profileId = ref.profileId;
return _c('div',{key:id,staticClass:"message-group__message",class:[
                        profileId === _vm.rightId
                            ? 'message-group__message--right'
                            : 'message-group__message--left'
                    ]},[_vm._v(" "+_vm._s(message)+" ")])}),0)])})],2),_c('transition',{attrs:{"name":"pop-up"}},[(!_vm.isScrolledToBottom)?_c('div',{staticClass:"scroll-to-bottom-pop-up",on:{"click":_vm.scrollToBottom}},[_c('span',[_vm._v("Scroll to Bottom")]),_c('img',{staticClass:"icon-xsm",attrs:{"src":require("@/assets/icons/chevron_down_icon.svg"),"alt":"down_arrow"}})]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }