<template>
    <div v-bind="$attrs" class="collapsible-panel-stateless">
        <slot name="header"></slot>

        <transition name="slide-down">
            <slot v-if="!isCollapsed" name="content"></slot>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'CollapsiblePanelStateless',
    props: {
        isCollapsed: {
            type: Boolean,
            required: true
        }
    }
}
</script>

<style lang="scss" scoped>
.collapsible-panel-stateless {
}
.slide-down-enter,
.slide-down-leave-to {
    transform: scaleY(0);
}
.slide-down-enter-active,
.slide-down-leave-active {
    transition: all 0.3s;
}
</style>
