import gql from 'graphql-tag'

export const SEND_MESSAGE = gql`
    mutation SendMessage($input: MessageInput!) {
        sendMessage(input: $input) {
            id
            lastMessage
            updatedAt
        }
    }
`
export const SEND_EVENTREQUEST_MESSAGE = gql`
    mutation EventRequestMessage($input: EventrequestMessageInput!) {
        eventRequestMessage(input: $input) {
            id
            lastMessage
            updatedAt
        }
    }
`
