// import { MY_CONVERSATION } from '@/graphql/queries/conversation'
import { mergeUniquesById } from '@/utils/common'
export default {
    data() {
        return {
            input: ''
        }
    },
    methods: {
        extractInputText() {
            const text = this.input
            this.input = ''
            return text
        },
        updateCacheOnSendMessage(responseName, queryObject) {
            return (cache, { data }) => {
                const { _id: id, ...restLastMessage } = data[responseName].lastMessage
                const newMessage = {
                    __typename: 'ComponentConversationMessage',
                    id,
                    ...restLastMessage
                }

                const { myConversation } = cache.readQuery(queryObject)
                const newMessages = mergeUniquesById([newMessage], myConversation.messages)
                cache.writeQuery({
                    ...queryObject,
                    data: {
                        myConversation: {
                            ...myConversation,
                            messages: newMessages
                        }
                    }
                })
            }
        }
    }
}
