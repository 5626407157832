<template>
    <div ref="body" class="chat">
        <slot name="top" />
        <div class="chat__message-container">
            <LoadingEllipsis v-if="loading">Loading</LoadingEllipsis>
            <div
                v-for="messageGroup in groupedMessages"
                :key="messageGroup[0].id + 'group'"
                class="message-group"
                :class="{ 'message-group--left': !isRightAligned(messageGroup[0].profileId) }"
            >
                <div
                    v-if="!isRightAligned(messageGroup[0].profileId)"
                    class="message-group__avatar-gutter"
                >
                    <img
                        :src="getAvatar(messageGroup[0].profileId)"
                        alt="concert-time"
                        class="message-group__avatar avatar-sm"
                    />
                </div>
                <div class="message-group__messages">
                    <div
                        v-for="{ message, id, profileId } in messageGroup"
                        :key="id"
                        class="message-group__message"
                        :class="[
                            profileId === rightId
                                ? 'message-group__message--right'
                                : 'message-group__message--left'
                        ]"
                    >
                        {{ message }}
                    </div>
                </div>
            </div>
        </div>
        <transition name="pop-up">
            <div v-if="!isScrolledToBottom" class="scroll-to-bottom-pop-up" @click="scrollToBottom">
                <span>Scroll to Bottom</span>
                <img src="@/assets/icons/chevron_down_icon.svg" class="icon-xsm" alt="down_arrow" />
            </div>
        </transition>
    </div>
</template>

<script>
import logger from '@/utils/logger.js'
const scrollBottomVariance = 300
export default {
    name: 'Chat',
    props: {
        members: {
            type: Array,
            required: true
        },
        messages: {
            type: Array,
            required: true
        },
        rightId: {
            type: String,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    emits: ['scrolled-top'],
    data() {
        return {
            isScrolledToBottom: true
        }
    },
    computed: {
        groupedMessages() {
            return this.groupBy(
                this.messages.filter((message) => message.message !== ''),
                'profileId'
            )
        }
    },
    watch: {
        messages: {
            handler(newMessages, oldMessages) {
                // Handles scrolling when messages are updated
                const self = this
                if (!oldMessages || !oldMessages.length) {
                    this.$nextTick(function() {
                        self.scrollToBottom()
                    })
                } else if (!newMessages) {
                    return
                } else {
                    const lastOldMessage = oldMessages[oldMessages.length - 1]
                    const lastNewMessage = newMessages[newMessages.length - 1]
                    const firstOldMessage = oldMessages[0]
                    const firstNewMessage = newMessages[0]

                    const hasNewMessageTop =
                        firstOldMessage &&
                        lastOldMessage &&
                        firstOldMessage.id !== lastOldMessage.id
                    const hasNewMessageBottom =
                        lastNewMessage &&
                        firstNewMessage &&
                        firstNewMessage.id !== lastNewMessage.id

                    const { scrollHeight, scrollTop, clientHeight } = this.$refs.body
                    const currentScrollPos = scrollTop + clientHeight

                    if (scrollHeight - scrollBottomVariance <= currentScrollPos) {
                        this.$nextTick(function() {
                            if (hasNewMessageBottom) {
                                self.scrollToBottom()
                            } else {
                                logger.log(lastNewMessage, firstNewMessage)
                            }
                        })
                    } else if (this.$refs.body.scrollHeight === 0) {
                        if (hasNewMessageTop) {
                            self.scrollToTop()
                        }
                    }
                }
            },
            immediate: true
        }
    },
    created() {
        const self = this
        this.$nextTick(function() {
            self.$refs.body.addEventListener('scroll', self.handleScroll)
            self.scrollToBottom()
        })
    },
    methods: {
        handleScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
            if (scrollHeight - scrollBottomVariance >= scrollTop + clientHeight) {
                this.isScrolledToBottom = false
            } else {
                this.isScrolledToBottom = true
            }
            if (scrollTop === 0) {
                this.$emit('scrolled-top')
            }
        },
        getAvatar(id) {
            return this.members.find(({ id: otherId }) => otherId === id).avatar
        },
        isRightAligned(id) {
            return this.rightId === id
        },
        scrollToBottom() {
            const self = this
            self.$refs.body.scrollTop = self.$refs.body.scrollHeight
        },
        scrollToTop() {
            const self = this
            self.$refs.body.scrollTop = 0
        },
        groupBy(arr, key) {
            // Group chronological messages from same user in order to
            // display them as a nice group.
            return arr.reduceRight((acc, x) => {
                if (!acc.length) {
                    // First element
                    return [[x]]
                }
                const eq = (acc, x) => {
                    return acc[0][0][key] === x[key]
                }
                if (eq(acc, x)) {
                    let [a, ...tail] = acc
                    return [[x, ...a], ...tail]
                } else {
                    return [[x], ...acc]
                }
            }, [])
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/sass/_variables.scss';

.pop-up-enter {
    transform: translateY(300px);
}
.pop-up-enter-to {
    transform: translateY(0);
}

.pop-up-enter-active {
    transition: transform 0.3s ease-in-out;
}

.pop-up-leave-to {
    transform: translateY(300px);
}
.pop-up-leave-active {
    transition: transform 0.3s ease-in-out;
}
.scroll-to-bottom-pop-up {
    position: fixed;
    width: 170px;
    bottom: 140px;
    padding: 0.5rem;
    border-radius: 5px;
    left: calc(50% - 85px);
    background: white;
    cursor: pointer;
    font-size: 12px;
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    justify-items: center;
    box-shadow: 0px 5px 5px 1px rgba(0, 0, 0, 0.1);
}

.avatar-gutter {
    display: grid;
    align-items: end;
    justify-items: center;
}
.message-group {
    display: grid;
    column-gap: 0.5rem;
    &--left {
        grid-template-columns: 38px 1fr;
    }

    &__messages {
        display: grid;
        row-gap: 0.25rem;
        grid-auto-flow: dense;
    }

    &__avatar-gutter {
        display: grid;
        align-items: end;
        justify-items: center;
    }

    &__avatar {
        width: 38px;
        height: 38px;
    }
    &__message {
        padding: 0.5rem 1rem;
        border-radius: 5px;
        max-width: 70vw;
        font-size: 14px;
        word-break: break-word;
        &--right {
            justify-self: end;
            background: $primary;
            color: $beige;
        }

        &--left {
            display: grid;
            justify-self: start;
            background: $white;
            color: $primary;
        }
    }
}

.chat {
    overflow: auto;
    padding: 1rem;

    &__details {
        display: grid;
        grid-template-columns: repeat(2, auto);
        place-content: start;
        row-gap: 4px;
        column-gap: 1rem;
        align-items: center;
        justify-items: start;

        small {
            line-height: 1rem;
        }
        p {
            font-size: 14px !important;
            padding: 0.5rem;
            line-height: 1rem;
            border-radius: 5px;
            background-color: #fff;
        }
    }
    &__message-container {
        display: grid;
        row-gap: 1rem;
        padding-top: 1rem;
        grid-auto-flow: dense;
    }
}

.scrollGradient {
    background: linear-gradient($light 33%, rgba(239, 143, 183, 0)),
        linear-gradient(rgba(239, 143, 183, 0), $light 66%) 0 100%,
        radial-gradient(farthest-side at 50% 0, rgba(34, 34, 34, 0.1), rgba(0, 0, 0, 0)),
        radial-gradient(farthest-side at 50% 100%, rgba(34, 34, 34, 0.1), rgba(0, 0, 0, 0)) 0 100%;
    background-color: $light;
    background-repeat: no-repeat;
    background-attachment: local, local, scroll, scroll;
    background-size: 100% 45px, 100% 45px, 100% 15px, 100% 15px;
}
</style>
